exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nleague-js": () => import("./../../../src/pages/nleague.js" /* webpackChunkName: "component---src-pages-nleague-js" */),
  "component---src-templates-blogpost-template-js": () => import("./../../../src/templates/blogpost-template.js" /* webpackChunkName: "component---src-templates-blogpost-template-js" */),
  "component---src-templates-cat-template-js": () => import("./../../../src/templates/cat-template.js" /* webpackChunkName: "component---src-templates-cat-template-js" */),
  "component---src-templates-gamecat-template-js": () => import("./../../../src/templates/gamecat-template.js" /* webpackChunkName: "component---src-templates-gamecat-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-result-template-js": () => import("./../../../src/templates/result-template.js" /* webpackChunkName: "component---src-templates-result-template-js" */),
  "component---src-templates-resultpost-template-js": () => import("./../../../src/templates/resultpost-template.js" /* webpackChunkName: "component---src-templates-resultpost-template-js" */)
}

